import React from "react";
import styled from "styled-components";
import SimulationResultCard from "./simulator-result";
import SimPlayer from "./sim-player";
import { Grid, Group, Select } from "@mantine/core";
import { getMoveSequence } from "./sim-steps-list";

const Container = styled.div`
  background-color: white;
  margin: 8px;
`;

const SelectionContainer = styled.div`
  padding: 8px;
  border-radius: 2px;
  min-height: 70px;
  background-color: #f1f2f2;
`;

const Title = styled.h5`
  padding: 8px 0 0 8px;
  display: inline;
`;

const TitleDetails = styled.span`
  padding-left: 16px;
  font-size: 90%;
  font-weight: 400;
`;

const TitleDetailHighlight = styled.div`
  font-weight: 600;
  display: inline;
`;

const ValidResultsContainer = styled.div`
  display: ${(props) => (props.$validResults ? "block" : "none")};
`;

const ErrorResultsContainer = styled.div`
  display: ${(props) => (props.$validResults ? "none" : "block")};
`;

export default class ResultSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSimResult: -1,
      validResults: true,
      errorMessage: "",
      sort: "Fewest Moves",
      sortedIds: [],
      spottedCarCounts: [],
    };
  }

  componentDidMount() {
    this.checkResultsAreValid();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.simResultIds !== this.props.simResultIds ||
      prevState.simResultIds !== this.state?.simResultIds ||
      prevProps.allYards !== this.props.allYards
    ) {
      this.checkResultsAreValid();
    }
  }

  onSimResultSelected = (e) => {
    const newState = {
      ...this.state,
      selectedSimResult: e,
    };
    this.setState(newState);
  };

  checkResultsAreValid = () => {
    const keys = Object.keys(this.props.simResults?.simulation_results ?? []);
    const isValid = keys.length > 0 && keys.length <= 10;

    let newSpottedCarCounts = [];
    keys.forEach((currKey) => {
      let currResult = getMoveSequence(
        this.props.simResults,
        currKey,
        this.props.allCars,
        this.props.initialTrackLayout,
        this.props.yardEngineIds
      );
      //currResult has the full sequence of moves...we are only interested in the number of cars spotted in the final step
      if (currResult) {
        let finalConfiguration = currResult.calculatedSteps.pop();

        if (finalConfiguration) {
          let currCount = 0;
          //this.props.simResults.orders is an array of track names that orders were requested for, i.e. ['Track 10','Track 12']
          this.props.simResults?.orders?.forEach((currOrder) => {
            let currRack = Object.values(this.props.racks ?? []).find((x) =>
              x.trackIds.includes(currOrder)
            );
            //add to the count for each order in the request
            currCount += Math.min(
              finalConfiguration.tracks?.[currOrder]?.carIds?.length ?? 0,
              Number(currRack.racklength)
            );
          });

          const currItem = {
            id: currKey,
            spottedCarCount: currCount,
          };
          newSpottedCarCounts.push(currItem);
        }
      }
    });

    // console.log("newSpottedCarCounts", newSpottedCarCounts);

    const newSortedIds = this.getSortedIds(this.state?.sort);

    const newState = {
      ...this.state,
      validResults: isValid,
      errorMessage: isValid ? "" : this.props.simResults?.simulation_results,
      spottedCarCounts: newSpottedCarCounts,
      sortedIds: newSortedIds,
    };
    this.setState(newState);
  };

  getSortedIds = (e) => {
    const sortedIds = [];
    if (
      this.props.simResults?.simulation_results &&
      Object.keys(this.props.simResults.simulation_results).length > 0 &&
      Object.keys(this.props.simResults.simulation_results).length < 10
    ) {
      // console.log('keys', Object.keys(this.props.simResults.simulation_results));
      if (e === "Fewest Moves") {
        if (this.props.simResults?.simulation_results) {
          const sortedMoves = Object.values(
            this.props.simResults.simulation_results
          ).sort((a, b) => a.total_moves - b.total_moves);
          for (const value of Object.values(sortedMoves)) {
            sortedIds.push(value.Simulation);
          }
        }
      } else {
        if (this.props.simResults?.simulation_results) {
          const sortedYardOrg = Object.values(
            this.props.simResults.simulation_results
          ).sort((a, b) => b.yard_org - a.yard_org);
          for (const value of Object.values(sortedYardOrg)) {
            sortedIds.push(value.Simulation);
          }
        }
      }
    }

    return sortedIds;
  };

  render() {
    return (
      <Container>
        <SelectionContainer>
          <ValidResultsContainer
            $validResults={this.state?.validResults ?? false}
          >
            <Grid>
              <Grid.Col span={8}>
                <Title>
                  Top Results
                  <TitleDetails>
                    Souce Yard:&nbsp;
                    <TitleDetailHighlight>
                      {" "}
                      {this.props.simResults?.source_yard?.join(", ") ?? ""}
                    </TitleDetailHighlight>
                    &nbsp;&nbsp;&nbsp; Target Yard:&nbsp;
                    <TitleDetailHighlight>
                      {(this.props.simResults?.target_yard ?? "").replace(
                        "Track ",
                        ""
                      )}
                    </TitleDetailHighlight>
                    &nbsp;&nbsp;&nbsp; Orders:&nbsp;
                    <TitleDetailHighlight>
                      {this.props.simResults?.orders?.join(", ") ?? ""}
                    </TitleDetailHighlight>
                  </TitleDetails>
                </Title>
              </Grid.Col>
              <Grid.Col span={4} ta={"right"}>
                Sort By:
                <Select
                  ml={8}
                  data={["Fewest Moves", "Yard Organization"]}
                  defaultValue={"Fewest Moves"}
                  display={"inline-flex"}
                  variant="unstyled"
                  onChange={this.getSortedIds}
                />
              </Grid.Col>
            </Grid>
            <Group>
              {this.state?.sortedIds?.map((resultId) => {
                // console.log(
                //   "resultId",
                //   resultId,
                //   "spottedCarCount",
                //   this.state?.spottedCarCounts,
                //   Object.values(this.state?.spottedCarCounts).find(
                //     (x) => x.id === resultId.toString()
                //   )
                // );
                return (
                  <SimulationResultCard
                    key={resultId}
                    resultInfo={
                      this.props.simResults.simulation_results[resultId]
                    }
                    spottedCarCount={
                      Object.values(this.state?.spottedCarCounts).find(
                        (x) => x.id === resultId.toString()
                      )?.spottedCarCount ?? 0
                    }
                    // spottedCarCounts={this.state?.spottedCarCounts}
                    selectSimResult={this.onSimResultSelected}
                    selectedSimResult={this.state?.selectedSimResult ?? null}
                  />
                );
              })}
            </Group>
          </ValidResultsContainer>
          <ErrorResultsContainer
            $validResults={this.state?.validResults ?? false}
          >
            Unable to fulfill request: {this.state?.errorMessage ?? null}
          </ErrorResultsContainer>
        </SelectionContainer>

        <SimPlayer
          allCars={this.props.allCars}
          initialTrackLayout={this.props.initialTrackLayout}
          allYards={this.props.allYards}
          selectedSimResult={this.state?.selectedSimResult ?? null}
          simResults={this.props.simResults}
          racks={this.props.racks}
          yardEngineIds={this.props.yardEngineIds}
        ></SimPlayer>
      </Container>
    );
  }
}
